/*******************************************************************

    audi-inputfield
    mobile-first
    error state

*******************************************************************/

.audi-inputfield.nm-form-error input {
	border-color: var(--color-red);
	color: var(--color-red);
}

.audi-inputfield.nm-form-error input::placeholder {
	color: var(--color-red);
}

.audi-inputfield.nm-form-error input + .audi-inputfield-prefix,
.audi-inputfield.nm-form-error input + .audi-inputfield-postfix,
.audi-inputfield.nm-form-error input:hover + .audi-inputfield-prefix,
.audi-inputfield.nm-form-error input:hover + .audi-inputfield-postfix {
	border-color: var(--color-red);
	color: var(--color-red);
}

.audi-inputfield.nm-form-error input:hover,
.audi-inputfield.nm-form-error input:hover + .audi-inputfield-prefix,
.audi-inputfield.nm-form-error input:hover + .audi-inputfield-postfix {
	box-shadow: 0px 1px 0 var(--color-red);
}
