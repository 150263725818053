/* generic rules */
.nm-icon-system-info-small {
	fill: none;
	height: 24px;
	stroke: #000;
	width: 24px;
}

.nm-icon-acceleration-large,
.nm-icon-announcements-large,
.nm-icon-available-from-date-large,
.nm-icon-calendar-events-large,
.nm-icon-car-large,
.nm-icon-car-search-large,
.nm-icon-charging-large,
.nm-icon-charging-station-large,
.nm-icon-consumption-display-large,
.nm-icon-consumption-display-electrical-large,
.nm-icon-drive-type-large,
.nm-icon-electric-range-large,
.nm-icon-emission-display-large,
.nm-icon-fuel-type-large,
.nm-icon-fuel-type-electrical-large,
.nm-icon-gear-box-large,
.nm-icon-gear-type-large,
.nm-icon-home-large,
.nm-icon-initial-registration-date-large,
.nm-icon-innovation-large,
.nm-icon-login-large,
.nm-icon-mileage-large,
.nm-icon-num-previous-owners-large,
.nm-icon-photo-large,
.nm-icon-power-display-large,
.nm-icon-power-large,
.nm-icon-sales-model-year-large,
.nm-icon-top-speed-large,
.nm-icon-upholstery-type-large,
.nm-icon-vehicle-signal-large,
.nm-icon-video-large,
.nm-icon-walk-large,
.nm-icon-warranty-type-12-months-large {
	fill: none;
	height: 48px;
	stroke: #000;
	width: 48px;
}

/* please note: some icons must not have a stroke, therefore are overwritten here */
.nm-icon-calendar-events-large,
.nm-icon-car-large,
.nm-icon-car-search-large {
	fill: inherit;
	stroke: none;
}
