.audi-range-slider__input {
	--range: calc(var(--max) - var(--min));
	--ratio: calc((var(--val) - var(--min)) / var(--range));
	--sx: calc(.5 * 26px + var(--ratio) * (100% - 26px)); /* 26px = Thumb Size */
	appearance: none;
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 26px;
	margin: 0;
	padding: 0;
	width: 100%;
}

.audi-range-slider__input::-webkit-slider-runnable-track {
	appearance: none;
	background-color: var(--color-grey-30);
	border: none;
	box-sizing: border-box;
	height: 2px;
	width: 100%;
}

.audi-range-slider__input::-moz-range-track {
	appearance: none;
	background-color: var(--color-grey-30);
	border: none;
	box-sizing: border-box;
	height: 2px;
	width: 100%;
}

.audi-range-slider__input::-ms-track {
	appearance: none;
	background-color: var(--color-grey-30);
	background-color: #b3b3b3; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	box-sizing: border-box;
	height: 2px;
	width: 100%;
}

.audi-range-slider__input::-moz-range-progress {
	appearance: none;
	background-color: var(--color-black);
	height: 2px;
}

.audi-range-slider__input::-ms-fill-lower {
	appearance: none;
	background-color: var(--color-black);
	background-color: #000; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	height: 2px;
}

.audi-range-slider__input::-ms-fill-upper { /* IE11 */
	appearance: none;
	background-color: var(--color-grey-30);
	background-color: #b3b3b3; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	height: 2px;
}

.audi-range-slider__input::-webkit-slider-thumb {
	appearance: none;
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-30);
	border-radius: 50%;
	box-sizing: border-box;
	height: 26px;
	margin-top: -12px;
	width: 26px;
}

.audi-range-slider__input::-moz-range-thumb {
	appearance: none;
	background-color: var(--color-white);
	border: 1px solid var(--color-grey-30);
	border-radius: 50%;
	box-sizing: border-box;
	height: 26px;
	width: 26px;
}

.audi-range-slider__input::-ms-thumb {
	appearance: none;
	background-color: var(--color-white);
	background-color: #fff; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	border: 1px solid var(--color-grey-30);
	border: 1px solid #b3b3b3; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	border-radius: 50%;
	box-sizing: border-box;
	height: 26px;
	margin-top: 0;
	width: 26px;
}

.audi-range-slider__input::-ms-tooltip {
	display: none;
}

.audi-range-slider__input::-ms-ticks-after {
	display: none;
}

.audi-range-slider__input::-ms-ticks-before {
	display: none;
}

.audi-range-slider__input:focus {
	box-shadow: none;
	outline: none;
}

.audi-range-slider__input::-webkit-slider-thumb:hover {
	border: 1px solid var(--color-black);
}

.audi-range-slider__input::-moz-range-thumb:hover {
	border: 1px solid var(--color-black);
}

.audi-range-slider__input::-ms-thumb:hover {
	border: 1px solid var(--color-black);
	border: 1px solid #000; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--js-enabled .audi-range-slider__input::-webkit-slider-runnable-track {
	background: linear-gradient(var(--color-black), var(--color-black)) 0 / var(--sx) 100% no-repeat var(--color-grey-30);
}

.audi-range-slider--negative .audi-range-slider__input::-moz-range-progress {
	background-color: var(--color-white);
}

.audi-range-slider--negative .audi-range-slider__input::-ms-fill-lower {
	background-color: var(--color-white);
	background-color: #fff;
}

.audi-range-slider--negative .audi-range-slider__input::-ms-fill-upper { /* IE11 */
	background-color: var(--color-grey-80);
	background-color: #333; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--negative .audi-range-slider__input::-webkit-slider-thumb {
	background-color: var(--color-black);
	border: 1px solid var(--color-grey-30);
}

.audi-range-slider--negative .audi-range-slider__input::-moz-range-thumb {
	background-color: var(--color-black);
	border: 1px solid var(--color-grey-30);
}

.audi-range-slider--negative .audi-range-slider__input::-ms-thumb {
	background-color: var(--color-black);
	background-color: #000; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
	border: 1px solid var(--color-grey-30);
	border: 1px solid #b3b3b3; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--grey .audi-range-slider__input::-webkit-slider-thumb {
	background-color: var(--color-grey-90);
}

.audi-range-slider--grey .audi-range-slider__input::-moz-range-thumb {
	background-color: var(--color-grey-90);
}

.audi-range-slider--grey .audi-range-slider__input::-ms-thumb {
	background-color: var(--color-grey-90);
	background-color: #1a1a1a; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */

}

.audi-range-slider--negative .audi-range-slider__input::-webkit-slider-runnable-track {
	background-color: var(--color-grey-80);
}

.audi-range-slider--negative .audi-range-slider__input::-moz-range-track {
	background-color: var(--color-grey-80);
}

.audi-range-slider--negative .audi-range-slider__input::-ms-track {
	background-color: var(--color-grey-80);
	background-color: #333; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--error .audi-range-slider__input::-moz-range-progress {
	background-color: var(--color-red);
}

.audi-range-slider--error .audi-range-slider__input::-ms-fill-lower {
	background-color: var(--color-red);
	background-color: #bb0a30; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--error .audi-range-slider__input::-webkit-slider-thumb {
	border: 1px solid var(--color-red);
}

.audi-range-slider--error .audi-range-slider__input::-moz-range-thumb {
	border: 1px solid var(--color-red);
}

.audi-range-slider--error .audi-range-slider__input::-ms-thumb {
	border: 1px solid var(--color-red);
	border: 1px solid #bb0a30; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider[data-state="disabled"] .audi-range-slider__input {
	pointer-events: none;
}

.audi-range-slider--negative .audi-range-slider__input::-webkit-slider-thumb:hover {
	border: 1px solid var(--color-white);
}

.audi-range-slider--negative .audi-range-slider__input::-moz-range-thumb:hover {
	border: 1px solid var(--color-white);
}

.audi-range-slider--negative .audi-range-slider__input::-ms-thumb:hover {
	border: 1px solid var(--color-white);
	border: 1px solid #fff; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--js-enabled.audi-range-slider--negative .audi-range-slider__input::-webkit-slider-runnable-track {
	background: linear-gradient(var(--color-white), var(--color-white)) 0 / var(--sx) 100% no-repeat var(--color-grey-80);
}

.audi-range-slider--js-enabled.audi-range-slider--error .audi-range-slider__input::-webkit-slider-runnable-track {
	background: linear-gradient(var(--color-red), var(--color-red)) 0 / var(--sx) 100% no-repeat var(--color-grey-30);
}

.audi-range-slider[data-state="disabled"] .audi-range-slider__input::-moz-range-progress,
.audi-range-slider[data-state="inactive"] .audi-range-slider__input::-moz-range-progress {
	background-color: var(--color-grey-30);
}

.audi-range-slider[data-state="disabled"] .audi-range-slider__input::-ms-fill-lower,
.audi-range-slider[data-state="inactive"] .audi-range-slider__input::-ms-fill-lower {
	background-color: var(--color-grey-30);
	background-color: #b3b3b3; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider[data-state="disabled"].audi-range-slider--negative .audi-range-slider__input::-moz-range-progress,
.audi-range-slider[data-state="inactive"].audi-range-slider--negative .audi-range-slider__input::-moz-range-progress {
	background-color: var(--color-grey-80);
}

.audi-range-slider[data-state="disabled"].audi-range-slider--negative .audi-range-slider__input::-ms-fill-lower,
.audi-range-slider[data-state="inactive"].audi-range-slider--negative .audi-range-slider__input::-ms-fill-lower {
	background-color: var(--color-grey-80);
	background-color: #333; /* EDGE Fallback for ::pseudo #shadow-root not rendering vars */
}

.audi-range-slider--js-enabled.audi-range-slider--negative.audi-range-slider--error .audi-range-slider__input::-webkit-slider-runnable-track {
	background: linear-gradient(var(--color-red), var(--color-red)) 0 / var(--sx) 100% no-repeat var(--color-grey-80);
}

.audi-range-slider--js-enabled.audi-range-slider[data-state="disabled"] .audi-range-slider__input::-webkit-slider-runnable-track,
.audi-range-slider--js-enabled.audi-range-slider[data-state="inactive"] .audi-range-slider__input::-webkit-slider-runnable-track {
	background: var(--color-grey-30);
}

.audi-range-slider--js-enabled.audi-range-slider[data-state="disabled"].audi-range-slider--negative .audi-range-slider__input::-webkit-slider-runnable-track,
.audi-range-slider--js-enabled.audi-range-slider[data-state="inactive"].audi-range-slider--negative .audi-range-slider__input::-webkit-slider-runnable-track {
	background: var(--color-grey-80);
}

.audi-range-slider__tickmarks {
	display: flex;
	font-size: 0;
	justify-content: space-between;
	line-height: 0;
	margin-bottom: -16px;
	margin-top: 20px;
	padding-top: 0px;
	width: 100%;
}

.audi-range-slider__tickmark-item {
	align-items: end;
	background-color: var(--color-grey-30);
	border: 1px solid var(--color-grey-30);
	border-radius: 50%;
	box-sizing: border-box;
	display: block;
	font-size: 0;
	height: 6px;
	justify-content: center;
	line-height: 0;
	width: 6px;
}

.audi-range-slider--negative .audi-range-slider__tickmark-item {
	background-color: var(--color-grey-80);
	border: 1px solid var(--color-grey-80);
}

.audi-range-slider__tickmark-item[data-state="filled"] {
	background-color: var(--color-black);
	border: 1px solid var(--color-black);
}

.audi-range-slider--negative .audi-range-slider__tickmark-item[data-state="filled"] {
	background-color: var(--color-white);
	border: 1px solid var(--color-white);
}

.audi-range-slider--error .audi-range-slider__tickmark-item[data-state="filled"] {
	background-color: var(--color-red);
	border: 1px solid var(--color-red);
}

.audi-range-slider[data-state="disabled"] .audi-range-slider__tickmark-item[data-state="filled"],
.audi-range-slider[data-state="inactive"] .audi-range-slider__tickmark-item[data-state="filled"] {
	background-color: var(--color-grey-30);
	border: 1px solid var(--color-grey-30);
}

.audi-range-slider[data-state="disabled"].audi-range-slider--negative .audi-range-slider__tickmark-item[data-state="filled"],
.audi-range-slider[data-state="inactive"].audi-range-slider--negative .audi-range-slider__tickmark-item[data-state="filled"] {
	background-color: var(--color-grey-80);
	border: 1px solid var(--color-grey-80);
}

.audi-range-slider__label {
	background-color: transparent;
	color: var(--color-grey-80);
	display: block;
	height: 24px;
	margin-bottom: 10px;
	text-align: right;
	width: auto;
}

.audi-range-slider--negative .audi-range-slider__label {
	color: var(--color-white);
}

.audi-range-slider--error .audi-range-slider__label {
	color: var(--color-red);
}

.audi-range-slider[data-state="disabled"] .audi-range-slider__label {
	color: var(--color-grey-30);
}

.audi-range-slider[data-state="disabled"].audi-range-slider--negative .audi-range-slider__label {
	color: var(--color-grey-50);
}
