/*******************************************************************

    audi-dropdown
    mobile-first
    disabled state

*******************************************************************/
.audi-dropdown.nm-form-disabled select {
	color: var(--color-grey-30);
	pointer-events: none;
}

.audi-dropdown.nm-form-disabled,
.audi-dropdown.nm-form-disabled select:hover {
	border-color: var(--color-grey-30);
	pointer-events: none;
}
