/*******************************************************************

    audi-inputfield
    mobile-first
    readonly state

*******************************************************************/

.audi-inputfield.nm-form-readonly input {
	border: none;
	color: #000;
	cursor: default;
	pointer-events: none;
}

.audi-inputfield.nm-form-readonly .audi-inputfield-prelabel {
	display: none;
}
