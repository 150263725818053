/*******************************************************************

    audi-checkbox
    mobile-first
    normal state

*******************************************************************/

.audi-checkbox {
	color: var(--color-grey-70);
	display: block;
	position: relative;
}

.audi-checkbox .audi-checkbox-input {
	display: none;
}

.audi-checkbox[data-state] .audi-checkbox-label,
.audi-checkbox .audi-checkbox-input + .audi-checkbox-label {
	color: var(--color-grey-70);
	cursor: pointer;
	display: block;
	padding-left: calc(24px + var(--space-s));
	position: relative;
	z-index: 2;
}

.audi-checkbox .audi-checkbox-label.audi-copy-s {
	padding-bottom: 2px;
	padding-top: 4px;
}

.audi-checkbox[data-state=true] .audi-checkbox-label,
.audi-checkbox[data-state] .audi-checkbox-label:hover,
.audi-checkbox .audi-checkbox-input + .audi-checkbox-label:hover,
.audi-checkbox .audi-checkbox-input:checked + .audi-checkbox-label {
	color: #000;
}

.audi-checkbox .audi-checkbox-symbol {
	box-shadow: inset 0 0 0 1px var(--color-grey-50);
	height: 24px;
	left: 0px;
	position: absolute;
	top: 0px;
	width: 24px;
	z-index: 1;
}

.audi-checkbox .audi-checkbox-label:hover ~ .audi-checkbox-symbol {
	box-shadow: inset 0 0 0 1px #000;
}

.audi-checkbox .audi-checkbox-symbol .nm-icon-select-small {
	height: 24px;
	opacity: 0;
	stroke: #000;
	width: 24px;
}

.audi-checkbox[data-state=true] .audi-checkbox-symbol > .nm-icon-select-small,
.audi-checkbox .audi-checkbox-input:checked ~ .audi-checkbox-symbol > .nm-icon-select-small {
	opacity: 1;
}

.audi-checkbox + .audi-form-description {
	margin-top: var(--space-m);
}
