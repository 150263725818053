/*******************************************************************

    audi-radiobutton
    mobile-first
    normal state

*******************************************************************/

.audi-radiobutton {
	color: var(--color-grey-70);
	display: inline-block;
}

.audi-radiobutton .audi-radiobutton-input {
	display: none;
}

.audi-radiobutton .audi-radiobutton-label {
	cursor: pointer;
	display: block;
}

.audi-radiobutton .audi-radiobutton-input + .audi-radiobutton-label::after {
	background-color: #e5e5e5;
	border-radius: 8px;
	content: " ";
	cursor: pointer;
	display: inline-block;
	height: 8px;
	left: 9px;
	position: absolute;
	top: 9px;
	width: 8px;
}

.audi-radiobutton .audi-radiobutton-input + .audi-radiobutton-label {
	padding-left: calc(24px + var(--space-s));
	position: relative;
}

.audi-radiobutton .audi-radiobutton-input + .audi-radiobutton-label::before {
	border: 1px solid var(--color-grey-50);
	border-radius: 24px;
	content: " ";
	cursor: pointer;
	display: inline-block;
	height: 24px;
	left: 0;
	position: absolute;
	top: 0;
	width: 24px;
}

.audi-radiobutton .audi-radiobutton-input:checked + .audi-radiobutton-label::before {
	border-color: #000;
}

.audi-radiobutton .audi-radiobutton-input:checked + .audi-radiobutton-label::after {
	background-color: #000;
	height: 12px;
	left: 7px;
	top: 7px;
	width: 12px;
}

.audi-radiobutton.nm-form-readonly .audi-radiobutton-input + .audi-radiobutton-label {
	color: var(--color-black);
	padding-left: 0;
}

.audi-radiobutton.nm-form-readonly .audi-radiobutton-input + .audi-radiobutton-label::before,
.audi-radiobutton.nm-form-readonly .audi-radiobutton-input + .audi-radiobutton-label::after {
	display: none;
}

.audi-radiobutton:not(.nm-form-disabled):hover .audi-radiobutton-input + .audi-radiobutton-label::after {
	height: 12px;
	left: 7px;
	top: 7px;
	width: 12px;
}

.audi-radiobutton .audi-radiobutton-prelabel {
	color: var(--color-grey-50);
	display: block;
	margin-bottom: var(--space-xs);
}
