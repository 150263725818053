/*******************************************************************

    audi-inputfield
    mobile-first
    normal state

*******************************************************************/

.audi-inputfield {
	box-sizing: border-box;
	color: var(--color-grey-70);
	display: inline-flex;
	flex-wrap: wrap;
	margin-bottom: var(--space-s);
	position: relative;
}

.audi-inputfield input,
.audi-inputfield .prelabel {
	display: block;
}

.audi-inputfield .audi-inputfield-prelabel {
	color: var(--color-grey-50);
	display: block;
	margin-bottom: var(--space-xs);
	width: 100%;
}

.audi-inputfield input {
	background-color: transparent;
	border-bottom: 1px solid var(--color-grey-50);
	border-style: none;
	color: var(--color-grey-70);
	flex-grow: 1;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.8rem;
	font-weight: var(--font-weight-normal);
	line-height: 3rem;
	min-width: 230px;
	order: 2;
	padding-bottom: var(--space-xs);
	position: relative;
	z-index: 2;
}

.audi-inputfield input::placeholder {
	color: var(--color-grey-70);
}

.audi-inputfield input:hover::placeholder {
	color: var(--color-black);
}

.audi-inputfield .audi-inputfield-prefix,
.audi-inputfield .audi-inputfield-postfix {
	border-bottom: 1px solid var(--color-grey-50);
	color: var(--color-grey-70);
	display: inline-block;
}

.audi-inputfield .audi-inputfield-prefix {
	order: 1;
	padding-right: var(--space-s);
}

.audi-inputfield .audi-inputfield-postfix {
	order: 3;
	padding-left: var(--space-s);
}

.audi-inputfield input:focus,
.audi-inputfield input:hover,
.audi-inputfield input:focus + .audi-inputfield-prefix,
.audi-inputfield input:hover + .audi-inputfield-prefix,
.audi-inputfield input:focus + .audi-inputfield-postfix,
.audi-inputfield input:hover + .audi-inputfield-postfix {
	border-color: var(--color-black);
	box-shadow: 0px 1px 0 var(--color-black);
	color: var(--color-black);
}
