/*******************************************************************

    audi-checkbox
    mobile-first
    error state

*******************************************************************/

.audi-checkbox[data-state].nm-form-error .audi-checkbox-label,
.audi-checkbox.nm-form-error .audi-checkbox-input ~ .audi-checkbox-label {
	color: var(--color-red);
}

.audi-checkbox[data-state].nm-form-error .audi-checkbox-label:hover,
.audi-checkbox.nm-form-error .audi-checkbox-input ~ .audi-checkbox-label:hover {
	color: #000;
}

.audi-checkbox.nm-form-error .audi-checkbox-symbol {
	box-shadow: inset 0 0 0 1px var(--color-red);
}

.audi-checkbox.nm-form-error .audi-checkbox-symbol .nm-icon-select-small {
	color: var(--color-red);
	stroke: var(--color-red);
}

.audi-checkbox.nm-form-error .audi-form-description {
	color: var(--color-red) !important;
}
