/*******************************************************************

    audi-checkbox
    mobile-first
    readonly state

*******************************************************************/

.audi-checkbox.nm-form-readonly {
	pointer-events: none;
}

.audi-checkbox.nm-form-readonly .audi-checkbox-label {
	color: #000;
}

.audi-checkbox.nm-form-readonly .audi-checkbox-symbol {
	box-shadow: none;
}

.audi-checkbox.nm-form-readonly .audi-checkbox-symbol .nm-icon-select-small {
	opacity: 1;
}
