/*******************************************************************

    audi-switch
    mobile-first
    normal state

*******************************************************************/

.audi-switch .audi-switch-label {
	cursor: pointer;
}

.audi-switch .audi-switch-label-status {
	color: #4c4c4c;
	white-space: nowrap;
}

.audi-switch:not(.audi-switch-option) .audi-switch-label-status {
	padding-left: 66px;
}

.audi-switch:not(.audi-switch-option) .audi-switch-label,
.audi-switch .audi-switch-toggle-outside {
	background: #fff;
	border: 1px solid #808080;
	border-radius: 18px;
	display: block;
	height: 24px;
	overflow: visible;
	position: relative;
	transition: left .15s ease-out;
	width: 48px;
}

.audi-switch.audi-switch-option .audi-switch-checkbox,
.audi-switch input.audi-switch-checkbox,
.nm-state-is-responsive .audi-switch input.audi-switch-checkbox {
	display: none;
}

.audi-switch .audi-switch-toggle-inside,
.audi-switch:not(.audi-switch-option) .audi-switch-label::after {
	background-color: #ccc;
	border-radius: 100%;
	content: "";
	display: block;
	height: 8px;
	left: 9px;
	position: absolute;
	top: 8px;
	transition: .15s;
	width: 8px;
}

.audi-switch .audi-switch-toggle-outside {
	cursor: pointer;
	margin: 0 -30px;
	order: 2;
	z-index: 1;
}

.audi-switch .audi-switch-toggle-inside,
.audi-switch .audi-switch-label:hover::after,
.audi-switch .audi-switch-checkbox:checked + .audi-switch-label::after {
	height: 12px;
	top: 6px;
	width: 12px;
}

.audi-switch .audi-switch-toggle-inside,
.audi-switch .audi-switch-checkbox:checked + .audi-switch-label::after {
	background-color: #000;
}

.audi-switch.audi-switch-option .audi-switch-label {
	order: 1;
	padding: 0 calc(18px + 24px) 0 0;
	z-index: 3;
}

.audi-switch:not(.audi-switch-option) .audi-switch-label:hover,
.audi-switch .audi-switch-checkbox:not(:checked) + .audi-switch-label:hover ~ .audi-switch-toggle-outside {
	border-color: #000;
}

.audi-switch .audi-switch-checkbox[readonly="readonly"] + .audi-switch-label {
	background-color: transparent;
	border: none;
	cursor: default;
	pointer-events: none;
}

.audi-switch.audi-switch-option .audi-switch-checkbox:checked + .audi-switch-label {
	cursor: default;
}

.audi-switch .audi-switch-checkbox[readonly="readonly"] + .audi-switch-label::after {
	content: none;
}

.audi-switch .audi-switch-checkbox[readonly="readonly"] + .audi-switch-label .audi-switch-label-status {
	padding: 0;
}

.audi-switch.audi-switch-option .audi-switch-label:hover .audi-switch-label-status,
.audi-switch .audi-switch-checkbox[readonly="readonly"] + .audi-switch-label .audi-switch-label-status,
.audi-switch.audi-switch-option .audi-switch-checkbox:checked + .audi-switch-label .audi-switch-label-status {
	color: #000;
}

.audi-switch .audi-switch-checkbox ~ .audi-switch-checkbox:checked ~ .audi-switch-toggle-outside .audi-switch-toggle-inside,
.audi-switch .audi-switch-checkbox:checked + .audi-switch-label::after {
	left: calc(48px - 9px - 12px);
}

.audi-switch .audi-switch-checkbox:checked + .audi-switch-label:hover::after {
	height: 8px;
	left: calc(48px - 9px - 8px);
	top: 8px;
	width: 8px;
}

.audi-switch.audi-switch-option {
	display: flex;
	flex-flow: row nowrap;
}

.audi-switch:not(.audi-switch-option) .audi-switch-checkbox + .audi-switch-label .audi-switch-label-active,
.audi-switch:not(.audi-switch-option) .audi-switch-checkbox:checked + .audi-switch-label .audi-switch-label-inactive {
	display: none;
}

.audi-switch .audi-switch-checkbox:checked + .audi-switch-label .audi-switch-label-active {
	color: #000;
	display: inline-block;
}

.audi-switch.audi-switch-option .audi-switch-label:last-of-type:not(:only-of-type) {
	order: 3;
	padding: 0 0 0 calc(18px + 24px);
}
