/*******************************************************************

    audi-radiobutton
    mobile-first
    error state

*******************************************************************/

.audi-radiobutton.nm-form-error .audi-radiobutton-label {
	color: var(--color-red);
}

.audi-radiobutton.nm-form-error .audi-radiobutton-input + .audi-radiobutton-label::before,
[data-theme=dark] .audi-radiobutton.nm-form-error .audi-radiobutton-input + .audi-radiobutton-label::before {
	border-color: var(--color-red);
}

.audi-radiobutton.nm-form-error .audi-radiobutton-input:checked + .audi-radiobutton-label::after,
[data-theme=dark] .audi-radiobutton.nm-form-error .audi-radiobutton-input:checked + .audi-radiobutton-label::after {
	background-color: var(--color-red);
}
