/*******************************************************************

    audi-dropdown
    mobile-first
    error state

*******************************************************************/

.audi-dropdown.nm-form-error select {
	border-color: var(--color-red);
	color: var(--color-red);
}

.audi-dropdown.nm-form-error select:hover {
	border-color: var(--color-red);
	box-shadow: 0px 1px 0 var(--color-red);
}
