/*******************************************************************

    audi-radiobutton
    mobile-first
    disabled state

*******************************************************************/

.audi-radiobutton.nm-form-disabled .audi-radiobutton-label {
	color: var(--color-grey-30);
	cursor: default;
	pointer-events: none;
}

.audi-radiobutton.nm-form-disabled .audi-radiobutton-input + .audi-radiobutton-label::before {
	border-color: var(--color-grey-30);
	cursor: default;
	pointer-events: none;
}

.audi-radiobutton.nm-form-disabled .audi-radiobutton-input + .audi-radiobutton-label::after {
	cursor: default;
	pointer-events: none;
}

.audi-radiobutton.nm-form-disabled .audi-radiobutton-input:checked + .audi-radiobutton-label::after {
	background-color: var(--color-grey-30);
}
