.audi-dropdown-flyout {
	display: block;
	margin-left: 4%;
	margin-right: 4%;
	max-width: 100%;
	min-width: 15vw;
	position: relative;
}

.audi-dropdown-flyout__label {
	background: transparent;
	border-bottom: 1px solid var(--color-grey-70);
	box-sizing: border-box;
	color: var(--color-black);
	cursor: pointer;
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding-bottom: calc(var(--space-xs) + 1px); /* 1px to prevent wobble effect on hover for 2px border */
	width: 100%;
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__label {
	border-bottom: 1px solid var(--color-grey-30);
}

.audi-dropdown-flyout__label:hover,
.audi-dropdown-flyout[data-state="open"] .audi-dropdown-flyout__label {
	border-bottom: 2px solid var(--color-black);
	padding-bottom: var(--space-xs);
}

.audi-dropdown-flyout[data-state="open"] .audi-dropdown-flyout__label {
	background: var(--color-white);
	padding: var(--space-s) 4% var(--space-xs);
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__label:hover,
.audi-dropdown-flyout.audi-dropdown-flyout--inverted[data-state="open"] .audi-dropdown-flyout__label {
	border-bottom: 2px solid var(--color-white);
}

.audi-dropdown-flyout.audi-dropdown-flyout--inverted[data-state="open"] .audi-dropdown-flyout__label {
	background: var(--color-grey-70);
}

.audi-dropdown-flyout .audi-down-small,
.audi-dropdown-flyout .nm-icon-cancel {
	color: var(--color-black);
	fill: var(--color-black);
	height: var(--icon-size-small);
	margin-top: auto;
	stroke: var(--color-black);
	stroke-width: 0;
	width: var(--icon-size-small);
}

.audi-dropdown-flyout.audi-dropdown-flyout--inverted .audi-down-small,
.audi-dropdown-flyout.audi-dropdown-flyout--inverted .nm-icon-cancel {
	color: var(--color-white);
	fill: var(--color-white);
	stroke: var(--color-white);
}

.audi-dropdown-flyout[data-state="open"] .nm-icon-cancel,
.audi-dropdown-flyout[data-state="closed"] .audi-down-small {
	display: block;
}

.audi-dropdown-flyout[data-state="closed"] .nm-icon-cancel,
.audi-dropdown-flyout[data-state="open"] .audi-down-small {
	display: none;
}

.audi-dropdown-flyout__label-wrap {
	display: inline-flex;
	flex-flow: column nowrap;
	max-width: calc(100% - var(--icon-size-small) - var(--space-s));
}

.audi-dropdown-flyout__module-label {
	color: var(--color-grey-70);
	margin-bottom: var(--space-xxs);
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__module-label {
	color: var(--color-grey-30);
}

.audi-dropdown-flyout__select {
	background-color: var(--color-grey-10);
	height: 100vh;
	list-style: none;
	max-height: 0;
	overflow: hidden;
	position: absolute;
	transition: max-height var(--transition-ease-in-out) var(--time-l);
	width: 100%;
	z-index: 1;
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__select {
	background-color: var(--color-grey-90);
	box-sizing: border-box;
}

.audi-dropdown-flyout__select-group {
	opacity: 0;
	transition: opacity var(--transition-ease-in-out) var(--time-l) var(--time-xl);
}

.audi-dropdown-flyout[data-state="open"] .audi-dropdown-flyout__select-group {
	opacity: 1;
}

.audi-dropdown-flyout__group-label {
	color: var(--color-grey-50);
	display: block;
	padding: var(--space-s) 8%;
}

.audi-dropdown-flyout__item {
	color: var(--color-black);
	display: block;
	padding: var(--space-s) 8%;
}

.audi-dropdown-flyout__item[data-state="selected"] {
	background-color: var(--color-grey-30);
}

.audi-dropdown-flyout__item:hover {
	color: var(--color-grey-50);
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__item,
.audi-dropdown-flyout--inverted .audi-dropdown-flyout__select {
	color: var(--color-white);
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__item:hover {
	color: var(--color-grey-40);
}

.audi-dropdown-flyout--inverted .audi-dropdown-flyout__item[data-state="selected"] {
	background-color: var(--color-grey-60);
}

.audi-dropdown-flyout__select-group .audi-dropdown-flyout__group-label + .audi-dropdown-flyout__items .audi-dropdown-flyout__item {
	padding: var(--space-s) 12%;
}

.audi-dropdown-flyout[data-state="open"] {
	height: 100%;
	left: 0;
	margin: 0;
	max-height: none;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
}

.audi-dropdown-flyout[data-state="open"] .audi-dropdown-flyout__select {
	display: block;
	margin: 0;
	max-height: 100vh;
	overflow-y: auto;
}

@media all and (min-width: 768px) {
	.audi-dropdown-flyout {
		margin-left: 0;
		margin-right: 0;
		max-width: 300px;
		width: 40vw;
	}

	.audi-dropdown-flyout[data-state="open"] {
		height: auto;
		left: auto;
		max-height: 100vh;
		position: relative;
		top: auto;
	}

	.audi-dropdown-flyout[data-state="open"]  .audi-dropdown-flyout__label,
	.audi-dropdown-flyout.audi-dropdown-flyout--inverted[data-state="open"] .audi-dropdown-flyout__label {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

	.audi-dropdown-flyout__group-label {
		padding: var(--space-s);
	}

	.audi-dropdown-flyout[data-state="open"] .nm-icon-cancel {
		display: none;
	}

	.audi-dropdown-flyout .audi-down-small {
		transition: transform var(--transition-ease-in-out) var(--time-l);
	}

	.audi-dropdown-flyout[data-state="open"] .audi-down-small {
		display: block;
		transform: rotate(180deg);
	}

	.audi-dropdown-flyout[data-state="open"] .audi-dropdown-flyout__select {
		height: auto;
		max-height: calc(((2 * var(--space-s)) + 24px) * 6); /* top + bottom padding + copy-m line height */ /* ~ 6 items */
	}

	.audi-dropdown-flyout__item {
		padding: var(--space-s);
	}

	.audi-dropdown-flyout__select-group .audi-dropdown-flyout__group-label + .audi-dropdown-flyout__items .audi-dropdown-flyout__item {
		padding: var(--space-s) var(--space-s) var(--space-s) var(--space-xl);
	}
}
