.audi-filter-selector {
	box-sizing: border-box;
	display: inline-block;
	position: relative;
}

.audi-filter-selector__input {
	display: none;
}

.audi-filter-selector__label {
	background-color: transparent;
	border: 1px solid var(--color-white);
	color: var(--color-white);
	cursor: pointer;
	display: inline-block;
	padding: var(--space-s) var(--space-m);
}

.audi-filter-selector--no-touch .audi-filter-selector__label:hover {
	background-color: var(--color-grey-10);
	border: 1px solid var(--color-grey-10);
	color: var(--color-black);
}

.audi-filter-selector__input:checked + .audi-filter-selector__label {
	background-color: var(--color-white);
	color: var(--color-black);
	padding: var(--space-s) var(--space-m);
}

.audi-filter-selector--no-touch .audi-filter-selector__input:checked + .audi-filter-selector__label:hover {
	background-color: var(--color-grey-10);
	border: 1px solid var(--color-grey-10);
	color: var(--color-black);
}
