.audi-filter-tag {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--color-black);
	cursor: pointer;
	display: inline-flex;
	padding: var(--space-s) var(--space-s) var(--space-s) var(--space-m);
}

.audi-filter-tag-on-white {
	background-color: var(--color-grey-05);
}

.audi-filter-tag--no-touch.audi-filter-tag:hover,
a.audi-filter-tag:hover,
button.audi-filter-tag:hover,
input.audi-filter-tag:hover {
	background-color: var(--color-grey-10);
}

.audi-filter-tag__text {
	margin-right: var(--space-s);
}

.audi-filter-tag__icon {
	display: block;
	flex: none;
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.audi-filter-tag--no-inline-image::after {
	background-image: url("./assets/icons/svg/cancel-small.svg");
	content: "";
	display: block;
	flex: none;
	height: var(--icon-size-small);
	margin-left: var(--space-s);
	width: var(--icon-size-small);
}
