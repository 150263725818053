/*******************************************************************

    audi-checkbox
    mobile-first
    disabled state

*******************************************************************/

.audi-checkbox.nm-form-disabled {
	pointer-events: none;
}

.audi-checkbox.nm-form-disabled .audi-checkbox-symbol {
	box-shadow: inset 0 0 0 1px var(--color-grey-30);
}

.audi-checkbox.nm-form-disabled .audi-checkbox-label,
.audi-checkbox.nm-form-disabled[data-state=true] .audi-checkbox-label,
.audi-checkbox.nm-form-disabled .audi-checkbox-input:checked + .audi-checkbox-label {
	color: var(--color-grey-30);
}

.audi-checkbox.nm-form-disabled .audi-checkbox-symbol .nm-icon-select-small {
	opacity: 0;
}
