/*******************************************************************

    audi-dropdown
    mobile-first
    valid state

*******************************************************************/

.audi-dropdown.nm-form-valid select {
	border-color: #000;
	color: #000;
}
