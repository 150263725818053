/*******************************************************************

    audi-dropdown
    mobile-first
    readonly state

*******************************************************************/

.audi-dropdown.nm-form-readonly {
	border: none;
	color: #000;
	pointer-events: none;
}

.audi-dropdown.nm-form-readonly .nm-icon-arrow {
	display: none;
}
