/*******************************************************************

    audi-form-description
    mobile-first
    normal state

*******************************************************************/

.audi-form-description {
	box-sizing: border-box;
	color: var(--color-grey-70) !important;
	display: block;
	margin-top: var(--space-s);
	padding-right: var(--space-l);
}

.nm-form-readonly + .audi-form-description {
	margin-top: var(--space-m);
}

.audi-form-description.nm-form-error {
	color: var(--color-red) !important;
}

.audi-form-description.nm-form-error + .audi-form-description {
	margin-top: var(--space-xs);
}
