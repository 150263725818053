/*******************************************************************

    audi-inputfield
    mobile-first
    valid state

*******************************************************************/

.audi-inputfield.nm-form-valid input {
	border-color: #000;
	color: #000;
}
