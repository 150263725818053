/*******************************************************************

    audi-inputfield
    mobile-first
    disabled state

*******************************************************************/

.audi-inputfield.nm-form-disabled input {
	border-color: var(--color-grey-30);
	color: var(--color-grey-30);
	cursor: default;
	pointer-events: none;
}

.audi-inputfield.nm-form-disabled input::placeholder {
	color: var(--color-grey-30);
}
