/*******************************************************************

    audi-dropdown
    mobile-first
    normal state

*******************************************************************/

.audi-dropdown {
	box-sizing: border-box;
	margin-bottom: var(--space-s);
	max-width: 100%;
	position: relative;
	width: 92vw;
}

.audi-dropdown .audi-dropdown-prelabel {
	color: var(--color-grey-50);
	display: block;
	margin-bottom: var(--space-xs);
}

.audi-dropdown select {
	appearance: none;
	background: none;
	border: 0;
	border-bottom: 1px solid var(--color-grey-50);
	border-radius: 0;
	color: var(--color-grey-70);
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.8rem;
	font-weight: var(--font-weight-normal);
	height: auto;
	line-height: 3rem;
	outline: none;
	padding: 0 var(--space-l) var(--space-xs) 0;
	position: relative;
	width: 100%;
	z-index: 2;
}

.audi-dropdown select::-ms-expand {
	display: none;
}

.audi-dropdown select:hover {
	border-color: var(--color-black);
	box-shadow: 0px 1px 0 var(--color-black);
	color: var(--color-black);
}

.audi-dropdown .nm-icon-arrow {
	bottom: 2px;
	color: var(--color-grey-70);
	display: inline-block;
	fill: currentColor;
	height: 24px;
	pointer-events: none;
	position: absolute;
	right: 0;
	stroke: currentColor;
	stroke-width: 0;
	transform: rotate(90deg);
	transition: transform .3s;
	width: 24px;
	z-index: 1;
}

@media all and (min-width: 768px) {
	.audi-dropdown {
		width: 36vw;
	}
}

@media all and (min-width: 1920px) {
	.audi-dropdown {
		width: 32vw;
	}
}
