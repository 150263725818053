/* legacy color overwrite for dark-theme */
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-copy-m,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-copy-m-bold,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-copy-s,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-copy-s-bold,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-1,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-1-normal,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-2,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-2-normal,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-3,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-headline-order-3-normal,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-ordered-list-s,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-ordered-list-m,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-unordered-list-s,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-unordered-list-m,
body:not(.nm-layer-fallback) [data-theme="dark"] b {
	color: var(--color-white, #fff);
}

/* begin audi link */
/* link m */
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m:active,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m[data-state="active"] {
	color: var(--color-white, #fff);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m:hover,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m:focus {
	color: var(--color-grey-30);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m[data-state="inactive"] {
	color: var(--color-grey-70);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted:active,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted[data-state="active"] {
	color: var(--color-black, #000);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted:hover,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted:focus {
	color: var(--color-grey-70);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-m--inverted[data-state="inactive"] {
	color: var(--color-grey-30);
}
/* link s */
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s:active,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s[data-state="active"] {
	color: var(--color-white, #fff);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s:hover,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s:focus {
	color: var(--color-grey-30);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s[data-state="inactive"] {
	color: var(--color-grey-70);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted:active,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted[data-state="active"] {
	color: var(--color-black, #000);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted:hover,
body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted:focus {
	color: var(--color-grey-70);
}

body:not(.nm-layer-fallback) [data-theme="dark"] .audi-link-s--inverted[data-state="inactive"] {
	color: var(--color-grey-30);
}
/* end audi link */

/* begin audi button */
[data-theme="dark"] .audi-button,
[data-theme="dark"] .audi-button:active,
[data-theme="dark"] .audi-button[data-state="active"] {
	background-color: var(--color-white, #fff);
	color: var(--color-black, #000);
}

[data-theme="dark"] .audi-button:hover,
[data-theme="dark"] .audi-button:focus {
	background-color: var(--color-grey-30);
}

[data-theme="dark"] .audi-button[data-state="inactive"] {
	background-color: var(--color-grey-60);
}

[data-theme="dark"] .audi-button--ghost,
[data-theme="dark"] .audi-button--ghost:active,
[data-theme="dark"] .audi-button--ghost[data-state="active"] {
	background-color: transparent;
	border-color: var(--color-white, #fff);
	color: var(--color-white, #fff);
}

[data-theme="dark"] .audi-button--ghost:hover,
[data-theme="dark"] .audi-button--ghost:focus {
	background-color: transparent;
	border-color: var(--color-grey-30);
	color: var(--color-grey-30);
}

[data-theme="dark"] .audi-button--ghost[data-state="inactive"] {
	background-color: transparent;
	border-color: var(--color-grey-60);
	color: var(--color-grey-60);
}
/* end audi button */

/* begin form elements */
/* checkboxes */
[data-theme="dark"] .audi-checkbox:not(.nm-form-error) .audi-checkbox-symbol {
	border-color: var(--color-grey-20);
}

[data-theme="dark"] .audi-checkbox.nm-form-readonly .audi-checkbox-label {
	color: var(--color-white, #fff);
}

[data-theme="dark"] .audi-checkbox.nm-form-disabled .audi-checkbox-label {
	color: var(--color-grey-30);
}

[data-theme="dark"] .audi-checkbox:not(.nm-form-error) .audi-checkbox-input + .audi-checkbox-label {
	color: var(--color-grey-20);
}

[data-theme="dark"] .audi-checkbox .audi-checkbox-input + .audi-checkbox-label:hover,
[data-theme="dark"] .audi-checkbox:not(.nm-form-error) .audi-checkbox-input:checked + .audi-checkbox-label {
	color: var(--color-white, #fff);
}

[data-theme="dark"] .audi-checkbox.nm-form-disabled .audi-checkbox-input:checked + .audi-checkbox-label {
	color: var(--color-grey-30);
}

[data-theme="dark"] .audi-checkbox .audi-checkbox-label:hover ~ .audi-checkbox-symbol {
	border-color: var(--color-white, #fff);
}

[data-theme="dark"] .audi-checkbox:not(.nm-form-error) .audi-checkbox-symbol .nm-icon-select-small {
	stroke: var(--color-white, #fff);
}

[data-theme="dark"] .audi-checkbox .audi-checkbox-label:hover + .audi-checkbox-symbol .nm-icon-select-small {
	stroke: var(--color-white, #fff);
}
/* radiobuttons */
[data-theme=dark] .audi-radiobutton .audi-radiobutton-input:checked + .audi-radiobutton-label::before {
	border-color: var(--color-white, #fff);
}

[data-theme=dark] .audi-radiobutton .audi-radiobutton-input:checked + .audi-radiobutton-label::after {
	background-color: var(--color-white, #fff);
}
/* end form elements */
